/* Card.css */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
  margin: 16px;
  width: 400px;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.card-title {
  font-size: 1.5em;
  margin: 0;
}

.card-description {
  font-size: 1.2em;
  color: #e7e6e6;
  margin: 8px 0;
}

.card-price {
  font-size: 0.8em;
  color: #e7e6e6;
  margin: 8px 0;
}

.card-contest-format {
  font-size: 0.8em;
  color: #e7e6e6;
  margin: 8px 0;
}

.before-start {
  color: green;
}

.before-end {
  color: red;
}

.card-frequency {
  font-size: 0.8em;
  color: red;
  margin: 8px 0;
}

.league-card {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.card-dates {
  font-size: 14px;
  color: white;
  margin: 5px 0;
}
